import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import { mark } from 'src/sdk/tests/mark'
import type {
  InstitutionalPageQueryQuery,
  InstitutionalPageQueryQueryVariables,
} from '@generated/graphql'
import { Image } from 'src/components/ui/Image'
import Newsletter from 'src/components/sections/Newsletter'

const Page: FCC<
  PageProps<InstitutionalPageQueryQuery, InstitutionalPageQueryQueryVariables>
> = (props) => {
  const {
    data: { site },
    location: { host, pathname },
  } = props

  const title = 'Dia dos Namorados'

  const canonical =
    host !== undefined
      ? `https://${host}${pathname.replace(/\/$/, '')}`
      : pathname.replace(/\/$/g, '')

  return (
    <>
      {/* SEO */}
      <GatsbySeo
        title={title}
        titleTemplate={
          site?.siteMetadata?.titleTemplate &&
          title.endsWith(site.siteMetadata.titleTemplate.replace('%s', ''))
            ? '%s'
            : site?.siteMetadata?.titleTemplate ?? ''
        }
        description={site?.siteMetadata?.description ?? ''}
        canonical={canonical}
        language="pt-br"
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            item: '/dia-dos-namorados',
            name: title,
            position: 1,
          },
        ]}
      />

      {/*
        Sections: Components imported from '../components/sections' only.
        Do not import or render components from any other folder in here.
      */}

      <Image
        src="https://miess.vtexassets.com/arquivos/dia-dos-namorados-1.jpg"
        alt="Dia dos namorados 1"
        width={1380}
        height={1245}
        style={{ width: '100%', height: 'auto' }}
        loading="eager"
      />
      <Image
        src="https://miess.vtexassets.com/arquivos/dia-dos-namorados-2.jpg"
        alt="Dia dos namorados 2"
        width={1380}
        height={185}
        style={{ width: '100%', height: 'auto' }}
        loading="eager"
      />

      <Newsletter />
    </>
  )
}

/**
 * This query is run during SSG
 * */
export const query = graphql`
  query DiaDosNamoradosPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
  }
`
Page.displayName = 'Page'

export default mark(Page)
